<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader class="flex-grow-0" title="Edycja lokalizacji">
      <template #prepend-item>
        <BackIconButton @click="$emit('close')"></BackIconButton>
      </template>
    </AbstractModalHeader>
    <v-card-text class="pb-0 flex-grow-1 d-flex flex-column">
      <div class="map mb-2 flex-grow-1" ref="map"></div>
      <p class="text-center mb-0">{{ address }}</p>
    </v-card-text>
    <DialogSaveButton @click="emitSelectNewAddress">Wybierz</DialogSaveButton>
  </DialogFactory>
</template>

<script>
import Vue from 'vue';
import vuetify from '@/core/config/vuetify';
import DialogFactory from '@/component/Dialog/dialog-factory';
import { MAP_ZOOM_TYPES } from '@/core/dict/google-map-dict';
import { PERMISSION_DICT, USED_WEB_APIS } from '@/core/dict/permission-dict';
import GeolocationService from '@/service/GeolocationService';
import BackIconButton from '@/component/Button/back-icon-button';
import CustomPin from '@/component/GMap/custom-pin';
import CurrentLocationButton from '@/component/GMap/current-location-button';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';

export default {
  name: 'EditSelectedPlaceModal',
  components: {
    AbstractModalHeader,
    BackIconButton,
    DialogSaveButton,
    DialogFactory,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    coords: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      geocoder: null,
      currentLocation: null,
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 900;
    },
    isMapInitialized() {
      return this.map !== null;
    },
    address() {
      return this.currentLocation?.address || 'Oznacz lokalizację';
    },
  },
  methods: {
    async initMap() {
      /* eslint-disable-next-line no-undef */
      const { Map } = await google.maps.importLibrary('maps');
      /* eslint-disable-next-line no-undef */
      const { Geocoder } = await google.maps.importLibrary('geocoding');

      this.geocoder = new Geocoder();
      this.map = new Map(this.$refs.map, {
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        center: this.currentLocation,
        zoom: MAP_ZOOM_TYPES.STREET,
        disableDefaultUI: true,
        mapTypeControl: true,
        zoomControl: this.isDesktop,
        gestureHandling: 'greedy',
      });

      this.map.addListener('drag', () => {
        this.currentLocation = {
          address: null,
          latitude: null,
          longitude: null,
        };
        this.marker.position = this.map.getCenter();
        this.customPin.$props.tooltipVisible = false;
      });

      this.map.addListener('dragend', () => {
        this.findAddressByPosition(this.marker.position);
      });

      this.map.addListener('zoom_changed', () => {
        this.map.panTo(this.marker.position);
      });

      await this.createCustomMarker();
      this.createCustomCurrentLocationControl();
    },
    async createCustomMarker() {
      /* eslint-disable-next-line no-undef */
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

      this.customPin = this.createComponent(CustomPin);
      this.marker = new AdvancedMarkerElement({
        map: this.map,
        content: this.customPin.$el,
        position: this.coords,
      });
      this.customPin.$props.tooltipVisible = true;
    },
    createComponent(component) {
      const ComponentClass = Vue.extend(component);
      const instance = new ComponentClass({
        vuetify,
      });
      instance.$mount();
      return instance;
    },
    findAddressByPosition(position) {
      return new Promise((resolve) => {
        this.geocoder.geocode(
          {
            latLng: position,
          },
          (responses) => {
            if (responses && responses.length > 0) {
              setTimeout(() => {
                this.currentLocation.address = responses[0].formatted_address;
                this.currentLocation.longitude = responses[0].geometry.location.lng();
                this.currentLocation.latitude = responses[0].geometry.location.lat();
                this.currentLocation.placeId = responses[0].place_id;
              }, 300);
            } else {
              this.$notify({
                group: 'global',
                type: 'warning',
                text: 'Nie znaleziono adresu dla zaznaczonego miejsca',
              });
            }
            resolve();
          }
        );
      });
    },
    emitSelectNewAddress() {
      this.$emit('selectNewAddress', this.currentLocation);
      this.$emit('close');
    },
    createCustomCurrentLocationControl() {
      this.currentLocationBtn = this.createComponent(CurrentLocationButton);
      this.currentLocationBtn.$el.addEventListener('click', this.findGeolococation);

      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.currentLocationBtn.$el);
    },
    findGeolococation() {
      this.getCurrentGeolocation().catch(() => {
        this.$notify({
          group: 'global',
          type: 'error',
          text: 'Użytkownik zablokował udostępnianie swojej lokalizacji',
        });
      });
    },
    trackGeolocationPermission() {
      navigator.permissions.query({ name: USED_WEB_APIS.GEOLOCATION }).then((perm) => {
        this.permission = perm;
        this.permission.addEventListener('change', this.geolocationPermissionListener);
      });
    },
    getCurrentGeolocation() {
      this.loading = true;
      return GeolocationService.getCoordinates()
        .then(({ coords }) => {
          this.currentLocationBtn.$props.gpsActive = true;
          this.marker.position = { lat: coords.latitude, lng: coords.longitude };
          this.findAddressByPosition(this.marker.position);
          this.map.setZoom(MAP_ZOOM_TYPES.STREET);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    checkGeolocationPermission() {
      return this.getCurrentGeolocation().catch(() => {});
    },
    geolocationPermissionListener({ target }) {
      this.currentLocationBtn.$props.gpsActive = target.state === PERMISSION_DICT.GRANTED;
    },
  },
  watch: {
    value(val) {
      if (val && !this.isMapInitialized) {
        this.initMap();
        this.trackGeolocationPermission();
        this.currentLocation = JSON.parse(JSON.stringify(this.coords));
      } else {
        this.currentLocation = JSON.parse(JSON.stringify(this.coords));
        this.marker.position = this.currentLocation;
        this.map.panTo(this.currentLocation);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.map {
  @include map-styles(360px);
}
</style>

<template>
  <div>
    <AbstractPassengerModal
      v-bind="$attrs"
      v-on="$listeners"
      :strategy="strategy"
      :entity="entity"
      :isLoading="isLoading"
    >
      <template #header="{ onClose }">
        <AbstractModalHeader title="Edytuj pasażera">
          <template #append-item>
            <RemoveIconButton v-if="userHasRightsToRemovePassenger" @click="openConfirmModal"></RemoveIconButton>
          </template>
          <template #close>
            <CloseIconButton @click="onClose"></CloseIconButton>
          </template>
        </AbstractModalHeader>
      </template>
    </AbstractPassengerModal>

    <ConfirmDialog
      :showDialog="isConfirmModalOpen"
      text="Czy na pewno usunąć pasażera?"
      @confirm="onConfirm"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import RemoveIconButton from '@/component/Button/remove-icon-button';
import ConfirmDialog from '@/component/Dialog/confirm-dialog';
import { UserMapper } from '@/shared/mappers/user.mapper';
import AbstractPassengerModal from './abstract-passenger-modal';
import EditPassengerStrategy from '../edit-passenger-strategy';

export default {
  name: 'EditPassengerModal',
  components: {
    AbstractPassengerModal,
    AbstractModalHeader,
    RemoveIconButton,
    CloseIconButton,
    ConfirmDialog,
  },
  props: {
    selectedPassenger: {
      required: true,
    },
  },
  data() {
    return {
      isConfirmModalOpen: false,
      isLoading: true,
      passenger: null,
    };
  },
  created() {
    this.$store
      .dispatch('passenger/fetchPassengerById', this.selectedPassenger.id)
      .then((data) => {
        this.passenger = { ...data, user: UserMapper.toModel(data.user) };
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters(['isManager', 'isTransportCompany', 'isHeadOfCompany']),
    userHasRightsToRemovePassenger() {
      return this.isManager || this.isTransportCompany || this.isHeadOfCompany;
    },
    strategy() {
      return new EditPassengerStrategy(this.$store);
    },
    entity() {
      return this.passenger;
    },
  },
  methods: {
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    onConfirm(val) {
      if (val) {
        this.deletePassenger();
      }
      this.isConfirmModalOpen = false;
    },
    deletePassenger() {
      this.$store.dispatch('passenger/deletePassenger', this.selectedPassenger).then(() => {
        this.$emit('close');
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-tooltip bottom :disabled="isUserCreated" nudge-left="110" nudge-bottom="-10">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <component
          :is="currentComponent"
          :car="car"
          :disabled="!isUserCreated"
          v-on="$listeners"
          data-testid="car-details"
        ></component>
      </span>
    </template>
    <div>Samochód można zdefiniować poprzez edycję istniejącego już użytkownika</div>
  </v-tooltip>
</template>

<script>
import AddCarButton from './add-car-button';
import EditCarButton from './edit-car-button';

export default {
  name: 'CarButtonFactory',
  props: {
    car: {
      required: true,
    },
    isUserCreated: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    currentComponent() {
      return this.car ? EditCarButton : AddCarButton;
    },
  },
};
</script>

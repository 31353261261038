<template>
  <autocomplete-field
    :class="{ 'warning--text': warningColor }"
    item-text="name"
    :icon="icon"
    :label="label"
    :items="passengerList"
    :value="value"
    outlined
    return-object
    hide-details="auto"
    :loading="passengerListLoading"
    @input="emitChange"
    data-cy="passenger-input"
    v-bind="$attrs"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { mapGetters } from 'vuex';

export default {
  name: 'PassengerField',
  inheritAttrs: false,
  components: {
    AutocompleteField,
  },
  props: {
    label: {
      type: String,
      default: 'Pasażer',
    },
    icon: {
      type: String,
      default: 'mdi-human-greeting-variant',
    },
    companyId: {},
    value: {
      required: false,
    },
    warningColor: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch('fetchPassengers', this.companyId);
  },
  computed: {
    ...mapGetters(['passengerList', 'passengerListLoading']),
  },
  methods: {
    emitChange(passenger) {
      this.$emit('input', passenger);
    },
  },
};
</script>

<style lang="scss" scoped>
$warning-color: #ff9800 !important;

.v-input.warning--text.error--text {
  color: $warning-color;

  ::v-deep.v-icon,
  ::v-deep.v-label,
  ::v-deep.v-messages {
    color: $warning-color;
  }
}
</style>
